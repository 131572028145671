import React, { useEffect } from 'react';

const ChatbotScript = () => {
    useEffect(() => {
        // Check if the script already exists
        const existingScript = document.getElementById('chat-init');
        if (!existingScript) {
            const script = document.createElement('script');
            script.id = 'chat-init';
            script.src = 'https://appli.lebavard.fr/account/js/init.js?id=5166297';
            script.async = true;


            document.body.appendChild(script);

        } else {

        }

        // Cleanup function to remove the script when component is unmounted
        return () => {
            if (existingScript) {
                document.body.removeChild(existingScript);

            }
        };
    }, []);

    return null;
};

export default ChatbotScript;
