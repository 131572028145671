import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from './AuthContext';
import { useCredits } from './CreditsContext';
import { db } from './firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import Header2 from './components/Header2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageContext from './LanguageContext';

const domain = process.env.REACT_APP_DOMAIN;

function MyPersonalityPage() {
    const { currentUser } = useAuth();
    const { credits, setCredits } = useCredits(); // Use CreditsContext
    const { language } = useContext(LanguageContext); // Get the current language
    const [upn, setUpn] = useState('');
    const [features, setFeatures] = useState([]);
    const [expandedFeature, setExpandedFeature] = useState(null);
    const [loadingFeatures, setLoadingFeatures] = useState({});
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchCreditsAndUPN = async () => {
            if (currentUser) {
                try {
                    const userDoc = await getDoc(doc(db, 'ep', currentUser.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setCredits(userData.credits);
                        setUpn(userData.upn);
                    }
                } catch (error) {
                    console.error("Error fetching user data. Please reload the page or close it and come back.");
                }
            }
        };

        fetchCreditsAndUPN();
    }, [currentUser, setCredits]);

    useEffect(() => {
        const featureNames = [
            'description', 'strengths', 'weaknesses', 'darkside', 'professional_life',
            'social_life', 'love_life', 'health', 'wealth', 'ideal_jobs'
        ];

        const fetchFeatures = async () => {
            if (currentUser) {
                try {
                    const personalityDoc = await getDoc(doc(db, 'personality', currentUser.uid));
                    if (personalityDoc.exists()) {
                        const personalityData = personalityDoc.data();
                        const featuresData = featureNames.map((name) => ({
                            name,
                            hasData: Boolean(personalityData[name]),
                            content: personalityData[name] || '',
                        }));
                        setFeatures(featuresData);
                    } else {
                        // Initialize the personality document if it doesn't exist
                        const featuresData = featureNames.map((name) => ({
                            name,
                            hasData: false,
                            content: '',
                        }));
                        setFeatures(featuresData);
                        await setDoc(doc(db, 'personality', currentUser.uid), {});
                    }
                } catch (error) {
                    console.error("Error fetching personality data. Please reload the page or close it and come back.", error);
                }
            }
        };

        fetchFeatures();
    }, [currentUser]);

    const apiEndpoints = {
        description: '/personality',
        strengths: '/personality_strength',
        weaknesses: '/personality_weakness',
        darkside: '/personality_darkside',
        professional_life: '/personality_professional',
        social_life: '/personality_social',
        love_life: '/personality_love',
        health: '/personality_health',
    };

    const handleCalculate = async (featureName) => {
        if (credits < 1) {
            navigate('/mycredits');
            return;
        }

        setLoadingFeatures((prevLoadingFeatures) => ({
            ...prevLoadingFeatures,
            [featureName]: true,
        }));

        try {
            const personalityParams = new URLSearchParams({ upn, language: language }).toString();
            const apiUrl = `${domain}/api${apiEndpoints[featureName]}?${personalityParams}`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const content = data.result;

            const personalityDocRef = doc(db, 'personality', currentUser.uid);
            await updateDoc(personalityDocRef, {
                [featureName]: content,
            });

            const userDocRef = doc(db, 'ep', currentUser.uid);
            await updateDoc(userDocRef, {
                credits: credits - 1,
            });

            setFeatures((prevFeatures) =>
                prevFeatures.map((feature) =>
                    feature.name === featureName ? { ...feature, hasData: true, content } : feature
                )
            );
            setCredits((prevCredits) => prevCredits - 1);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to retrieve data: ' + error.message);
        } finally {
            setLoadingFeatures((prevLoadingFeatures) => ({
                ...prevLoadingFeatures,
                [featureName]: false,
            }));
        }
    };

    const handleExpand = (featureName) => {
        setExpandedFeature((prev) => (prev === featureName ? null : featureName));
    };

    const renderParagraphs = (text) => {
        return text.split('\n\n').map((paragraph, index) => (
            <p key={index} className="mb-2">{paragraph}</p>
        ));
    };

    const renderListItems = (text) => {
        return (
            <ul className="list-disc list-inside">
                {text.split('\n').map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 md:mx-auto pt-36">
                <h1 className="text-4xl mb-5">{t('mypersonality.title')}</h1>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                    {features.map((feature) => (
                        <div key={feature.name} className={`relative ${expandedFeature === feature.name ? 'col-span-1 md:col-span-3 text-left' : 'col-span-1 text-center'}`}>
                            {expandedFeature === feature.name ? (
                                <button className="absolute top-2 right-2 border border-gray-400 text-gray-500 rounded w-8 h-8 text-md" onClick={() => handleExpand(null)}>
                                    &times;
                                </button>
                            ) : ""}
                            <div className="person-feature-card p-6 bg-white rounded-lg shadow-md">
                                <h3 className="font-medium text-2xl mb-4 capitalize">{t(`mypersonality.${feature.name}`)}</h3>
                                {expandedFeature === feature.name ? (
                                    <>
                                        <div className="person-feature-content relative">
                                            {feature.name === 'strengths' || feature.name === 'weaknesses'
                                                ? renderListItems(feature.content)
                                                : renderParagraphs(feature.content)}
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <button className="person-close-button bg-blue-500 text-white rounded px-3 py-2 mt-4" onClick={() => handleExpand(null)}>{t('close')}</button>
                                        </div>
                                    </>
                                ) : (
                                    <button
                                        onClick={() => feature.hasData ? handleExpand(feature.name) : handleCalculate(feature.name)}
                                        disabled={loadingFeatures[feature.name] || feature.name === 'wealth' || feature.name === 'ideal_jobs'}
                                        className={`mt-2 py-2 px-5 rounded ${feature.hasData ? 'bg-green-500 text-white' : 'bg-blue-500 text-white hover:bg-blue-600'} disabled:bg-gray-300 disabled:cursor-not-allowed`}
                                    >
                                        {loadingFeatures[feature.name] ? (
                                            <>
                                                <div className="inline-block border-t-transparent border-4 border-white rounded-full w-5 h-5 mr-2 animate-spin"></div>
                                                {t('mypersonality.calculating')}
                                            </>
                                        ) : feature.name === 'wealth' || feature.name === 'ideal_jobs' ? (
                                            t('mypersonality.coming_soon')
                                        ) : feature.hasData ? (
                                            t('mypersonality.read_button')
                                        ) : credits < 1 ? (
                                            t('mypersonality.add_credits')
                                        ) : (
                                            t('mypersonality.calculate_button')
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MyPersonalityPage;
